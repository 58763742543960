<template>
  <div
  @click="handleClick"
  class="is-flex is-size-6 chat-vote is-clickable has-background-white is-align-items-center"
  :class="{ 'expanded': isExpanded }"
  style="gap: 5px;"
  >
    <div v-if="isLoading">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else-if="chat.votes && chat.votes.length > 0">
      {{ chat.votes.length }}
    </div>
    <i :class="chat.votes && chat.votes.includes($store.state.adventureTeam.teamName) ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'"></i>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'ChatVote',
  props: ['chat'],
  data() {
    return {
      isExpanded: false,
      clickCount: 0,
      lastClickTime: 0,
      cooldown: 10000,
      isLoading: false
    }
  },
  methods: {
    async processVote () {
      this.isLoading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      const res = await masterFunction({
          methodName: 'vote-chat',
          teamCode: this.$store.state.teamCode,
          chatId: this.chat.id
      })
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    handleClick() {
      if (this.$store.state.adventureTeam.teamName === this.chat.teamName) {
        alert('You cannot thumbs up your own submission.')
        return
      }

      const currentTime = Date.now()
      if (this.clickCount >= 2 && (currentTime - this.lastClickTime) < this.cooldown) {
        const remainingTime = Math.ceil((this.cooldown - (currentTime - this.lastClickTime)) / 1000)
        alert(`Please wait ${remainingTime} more seconds.`)
        return
      }

      this.isExpanded = true
      setTimeout(() => {
        this.isExpanded = false
      }, 200)

      this.processVote()

      this.clickCount++
      this.lastClickTime = currentTime

      if (this.clickCount === 2) {
        setTimeout(() => {
          this.clickCount = 0
        }, this.cooldown)
      }
    }
  }
}
</script>

<style scoped>
.chat-vote {
  border-radius: 16px;
  padding: 2px 12px;
  border: 1px solid rgb(162, 162, 162);
  transition: transform 0.2s;
  height: 26px;
  color: rgb(162, 162, 162);
}
.chat-vote.expanded {
  transform: scale(1.4);
}
</style>

<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'chat'}"/>
        <div class="container has-text-centered mb-1">
          <div class="pt-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ t($store.state.uiMods.chat) }}
          </div>
        </div>
        <div class="container mb-6">
          <div class="is-size-6 has-text-grey mb-5 px-4 has-text-centered">
            {{ $store.state.uiMods.chatSubtext ? $store.state.uiMods.chatSubtext : chatSubtext}}
          </div>
          <div
          v-if="chatSubtext && chatSubtext.length > 0">
            <FormInput
            v-if="!isImage"
            class="mb-1"
            :size="'medium'" :title="t('Say something...')" :type="'text'" :value="text" v-on:update="text = $event"
            translate="no"
            />
            <FormInput
            v-else
            :key="`chatImage-${imageResetter}`"
            class="mb-3"
            :size="'medium'" :title="'Upload something...'" :type="'judged-image'" :value="imageDataUrl" v-on:update="imageDataUrl = $event"
            />
            <div class="level is-mobile">
              <div class="level-left">
                <div v-if="(adventureTeam.allowFaciMessaging || adventureTeam.allowSessionChat || adventureTeam.teamMembers.length > 1) ** !isImage && !text || isImage" class="level-item has-text-centered mb-2">
                  <div @click="toggleType()"
                  class="button is-small is-primary-colors has-text-weight-semibold is-fullwidth mt-0"
                  >
                    <i v-if="isImage" class="fas fa-quote-left"></i>
                    <i v-if="!isImage" class="fas fa-image mr-2"></i>
                    {{ isImage ? '' : t('Send image instead') }}
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div v-if="imageDataUrl || text" class="level-item has-text-centered mb-2">
                  <div class="has-text-grey is-fullwidth is-size-7 mt-0"
                  >
                    Send to...
                  </div>
                </div>
                <div v-if="(imageDataUrl || text) && adventureTeam.allowFaciMessaging" class="level-item has-text-centered mb-2 mr-1">
                  <div @click="(isImage && imageDataUrl || !isImage && text) && !loading ? addFaciChat() : null"
                  class="button is-small is-primary-colors has-text-weight-semibold is-fullwidth mt-0"
                  v-bind:class="{'is-light is-loading': loading}"
                  :disabled="isImage && !imageDataUrl || !isImage && !text"
                  >
                    <i class="fas fa-headset mr-2"></i>
                    get help
                  </div>
                </div>
                <div v-if="adventureTeam.allowSessionChat && (imageDataUrl || text)" class="level-item has-text-centered mb-2 mr-1">
                  <div @click="(isImage && imageDataUrl || !isImage && text) && !loading ? addChat(true) : null"
                  class="button is-small is-primary-colors has-text-weight-semibold is-fullwidth mt-0"
                  v-bind:class="{'is-light is-loading': loading}"
                  :disabled="isImage && !imageDataUrl || !isImage && !text"
                  >
                    <i class="fas fa-users mr-2"></i>
                    entire event
                  </div>
                </div>
                <div v-if="adventureTeam.teamMembers.length > 1 && (imageDataUrl || text)" class="level-item has-text-centered mb-2 mr-1">
                  <div @click="(isImage && imageDataUrl || !isImage && text) && !loading ? addChat(false) : null"
                  class="button is-small is-primary-colors has-text-weight-semibold is-fullwidth mt-0"
                  v-bind:class="{'is-light is-loading': loading}"
                  :disabled="isImage && !imageDataUrl || !isImage && !text"
                  >
                    <i class="fas fa-user-friends mr-2"></i>
                    my team
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(chat, index) in chats.slice().reverse()" :key="index">
            <div
            class="container has-rounded-corners pl-2 pt-2 pb-2 mb-1"
            v-bind:class="{
              'chat-box-animation': submittedText && index === 0,
              'has-background-primary-light': !chat.name
            }"
              :key="chat+chats.length"
            >
              <div class="is-flex is-justify-content-space-between is-align-items-center">
                <div v-if="!chat.name" class="has-text-weight-semibold">
                  <i class="fas fa-headset"></i>
                  Facilitator:
                </div>
                <div v-else-if="chat.name && !chat.teamName" class="notranslate has-text-weight-semibold">{{ chat.name }}:</div>
                <div v-else-if="chat.name && chat.teamName" class="notranslate has-text-weight-semibold"><i class="fas fa-users mr-2"></i>{{ `${chat.name} ${chat.teamName !== chat.name ? ` (${chat.teamName})` : '' }` }}:</div>
                <div v-if="chat.createdAt" class="is-size-7 has-text-grey-light mr-2">
                  {{
                    new Date().toDateString() === chat.createdAt.toDate().toDateString() ?
                    chat.createdAt.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) :
                    chat.createdAt.toDate().toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
                  }}
                </div>
              </div>
              <div class="is-flex is-align-items-flex-end is-flex-wrap-wrap pr-2" style="max-width: 100%; gap: 5px;">
                <img v-if="chat.imageUrl" class="chat-image-modifier is-clickable" :src="chat.imageUrl">
                <p v-else class="notranslate" style="white-space: pre-line">{{ chat.text }}</p>
                <ChatVote v-if="adventureTeam.uiMods.enableVoting && chat.id" :chat="chat"/>
                <!-- <div v-if="!chat.name" class="has-text-grey is-size-7">Broadcasted message - facilitators cannot see your reply or the Team Chat</div> -->
              </div>
            </div>
          </div>
          <div v-if="chats.length === 0" class="is-size-6 has-text-grey mb-5 px-4 has-text-centered mt-6 pt-6">
            <i class="fas fa-comments mr-1"></i>
            Messages will appear here.
          </div>
          <div class="container has-text-centered mt-3 mb-5 pb-5">
            <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
              <i class="fas fa-clipboard mr-2"></i>
              Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/forms/FormInput'
import ViewsStat from '@/components/ViewsStat.vue'
import ChatVote from '@/components/ChatVote.vue'
import { compressImage } from '../utils/utils.ts'

export default {
  name: 'Chat',
  components: {
    FormInput,
    ViewsStat,
    CardNavigation,
    ChatVote
  },
  data(){
    return {
      loading: false,
      isImage: false,
      imageDataUrl: null,
      submittedText: false,
      imageResetter: 0,
      text: ''
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    chats(){
      if(this.adventureTeam){
        return this.adventureTeam.chats.map(chat => {
          if (chat.text && chat.text.includes('(To Faci)')) {
            chat.text = chat.text.replace('(To Faci)', `(${this.t("To Faci")})`)
          }
          return chat
        })
      } else {
        return null
      }
    },
    chatSubtext(){
      let text = 'Chat with '
      const fills = []
      if(this.adventureTeam.teamMembers && this.adventureTeam.teamMembers.length > 1){
        fills.push('your teammates')
      }
      if(this.adventureTeam.allowSessionChat){
        fills.push('others in this session')
      }
      if(this.adventureTeam.allowFaciMessaging){
        fills.push(`the ${this.$store.state.uiMods.facilitator.toLowerCase()} to get help`)
      }
      if(fills.length > 1){
        text += fills.slice(0, -1).join(', ') + ', or ' + fills.slice(-1)
        text += '.'
      } else if (fills.length === 1) {
        text += fills[0]
      } else {
        text = ''
      }
      return text
    }
  },
  updated() {
    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })
  },
  methods: {
    showPhotoViewer(event){
      this.$store.commit('showPhotoViewer', event.target.src)
    },
    toggleType(){
      this.isImage = !this.isImage
      this.imageDataUrl = null
      this.imageResetter += 1
    },
    async processImage() {
      if (this.imageDataUrl.ext) {
        const compressedImage = await compressImage(this.imageDataUrl.image)
        const filename = Date.now().toString() + '.webp'
        await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .put(compressedImage)
        await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .getDownloadURL().then(downloadURL => {
          this.imageDataUrl = downloadURL
        })
      }
    },
    async addChat(isSessionWide) {
      if(this.loading) return
      this.loading = true
      if (this.imageDataUrl) await this.processImage()
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'add-chat',
        teamCode: this.$store.state.teamCode,
        userName: this.$store.state.userName,
        isSessionWide,
        imageDataUrl: this.imageDataUrl,
        text: !this.isImage ? this.text : null
      }).then(res => {
        this.text = ''
        this.submittedText = true
        this.imageDataUrl = null
        this.imageResetter += 1
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    async addFaciChat(){
      if(this.loading) return
      this.loading = true
      if (this.imageDataUrl) await this.processImage()
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
        methodName: 'message-faci',
        teamCode: this.$store.state.teamCode,
        userName: this.$store.state.userName,
        text: this.text,
        imageUrl: this.imageDataUrl,
      }).then(res => {
        this.text = ''
        this.submittedText = true
        this.imageDataUrl = null
        this.imageResetter += 1
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.$store.commit('updateShowChatAlert', false)
    this.$store.dispatch('enterPage', {
      pageName: 'chat'
    })

    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })
  },
  beforeDestroy() {
    this.$store.commit('updateShowChatAlert', false)
  }
}
</script>

<style>
@keyframes chat-box-animation-name {
  0%   { background-color: #FED892; }
  100% { background-color: #ffffff; }
}

.chat-box-animation {
  animation: chat-box-animation-name 1s ease-in forwards;
}

.chat-image-modifier {
  max-height: 250px;
  max-width: 350px;
  border-radius: 15px;
}

.chat-box-modifier {
  max-width: 390px;
}
</style>
